import React from "react";
import { graphql } from "gatsby";
import { Helmet } from 'react-helmet'
import Link from 'gatsby-link'

import Layout from "../components/layout";
import Share from "../components/share";
import Newsletter from "../components/newsletter";

import { TwitterIcon, LinkedinIcon } from "react-share";

import './blog.css'

import Image from "../images/connerfritz.jpg";

export const Blog = ({ data: { site: { siteMetadata: { title, aboutMe, siteUrl } }, markdownRemark } }) => {
  const { frontmatter, html, fields } = markdownRemark;

  return (
    <Layout hideHeaderImage={true}>
      <Helmet
        title={`${frontmatter.title} | ${title}`}
      >
        <meta itemprop="name" content={`${frontmatter.title} | ${title}`} />
        <meta itemprop="description" content={frontmatter.summary} />
        <meta itemprop="image" content={`${siteUrl}${fields.slug}twitter-card.jpg`} />
        <meta itemprop="keywords" content={frontmatter.tags.join(",")} />

        <meta property="og:url" content={`${siteUrl}${frontmatter.path}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`${frontmatter.title} | ${title}`} />
        <meta property="og:description" content={frontmatter.summary} />
        <meta property="og:image" content={`${siteUrl}${fields.slug}twitter-card.jpg`} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${frontmatter.title} | ${title}`} />
        <meta name="twitter:description" content={frontmatter.summary} />
        <meta name="twitter:image" content={`${siteUrl}${fields.slug}twitter-card.jpg`} />
        <meta name="twitter:site" content="@connerfritz" />
        <meta name="twitter:creator" content="@connerfritz" />
      </Helmet>
      <div className="bg-white rounded py-4 px-6 -mx-6 mb-4 shadow">
        <h1 className="BlogTitle text-4xl font-bold leading-none">
          {frontmatter.title}
          {frontmatter.subtitle ? <div className="text-xl font-medium leading-normal">{frontmatter.subtitle}</div> : null }
        </h1>
        
        <div className="ArticleInfo mt-2">
          <Share className="inline-block mr-4" url={`${siteUrl}${frontmatter.path}`} />
          <span className="text-sm italic text-gray-700">Published {frontmatter.date}</span>
        </div>
      </div>
      
      <div
        className="blog-body prose max-w-full"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <p className="mt-6 font-medium">If you liked this you can also follow me on <a className="text-indigo-500" href="https://twitter.com/connerfritz">Twitter</a>, check out my <a className="text-indigo-500" href="https://github.com/connerfritz">GitHub</a>, and take a look at my other <Link className="text-indigo-500" to="/blog">blog posts</Link>.</p>
      <Share url={`${siteUrl}${frontmatter.path}`} />
      <div className="AboutAuthor text-center md:text-left mt-2 md:mt-6 flex-row md:flex bg-white rounded px-4 py-2 md:py-8 -m-4 md:-mx-6 md:px-6 shadow py-4">
        
        <div className="mr-4 text-center">
          <img src={Image} className="inline-block" alt="Conner Fritz" />
          <div className="text-center mt-2">
            <a className="inline-block mr-1" href="https://twitter.com/connerfritz" target="_blank" rel="noreferrer"><TwitterIcon size={24} round={true} /> </a>
            <a className="inline-block mr-1" href="https://linkedin.com/in/connerfritz" target="_blank" rel="noreferrer"><LinkedinIcon size={24} round={true} /> </a>
          </div>
        </div>
        <div>
          <h3 className="font-medium text-xl">Conner Fritz</h3>
          <div className="">{aboutMe}</div>
          <div className="mt-6 md:mt-4 mx-auto">
            <h4 className="font-medium text-md">Join my mailing list</h4>
            <div className="mb-2 font-thin">Be the first to know when I write new content.</div>
            <Newsletter className="bg-gray-100 shadow-inner" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    site {
      siteMetadata {
        title
        aboutMe
        siteUrl
      }
    }
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        tags
        title
        subtitle
        path
        summary
      }
      fields {
        slug
      }
    }
  }
`;

export default Blog;